var _this13 = this;
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import { uploadFile } from '../../api/qiniuApI';
export default {
  name: "Member",
  data: function data() {
    return {
      // 机构信息
      infoOrg: this.Ls.getParseToken('nof_orgInfo'),
      // 机构Id
      oId: '',
      interior: '',
      //是否为内部机构0否，1是
      // 用户信息
      userInfo: this.Ls.getParseToken("nof_userInfo"),
      // 是否为体验园字段
      isExpOrg: false,
      // 筛选
      filter: [{
        placeholder: '用户角色',
        value: '',
        type: 'select',
        list: []
      }, {
        placeholder: '用户状态',
        value: '',
        type: 'select',
        list: [{
          id: '1',
          name: '正常'
        }, {
          id: '-1',
          name: '禁用'
        }]
      }, {
        placeholder: '手机号',
        value: '',
        type: 'text',
        list: []
      }
      // {
      //   placeholder:'用户名',
      //   value:'',
      //   type:'text',
      //   list:[]
      // }
      ],

      // List接口
      postListData: {
        // name:'',
        rId: '',
        // interior:'',
        state: '',
        pageSize: '',
        pageIndex: '',
        oId: '',
        mobile: ''
      },
      tableKey: 1,
      //表格的key
      tableData: [],
      total: 0,
      //页数总数
      currentPage: 1,
      // 当前页数
      pageSize: 10,
      //每页个数
      // drawer抽屉-数据
      drawer: false,
      //是否显示弹框
      direction: "rtl",
      drawerState: "",
      drawerData: {
        oId: "",
        uId: "",
        rId: "",
        username: "",
        mobile: "",
        email: "",
        gender: "",
        avatar: "",
        state: 1,
        position: ''
      },
      positionOptions: [{
        id: 1,
        name: "园长"
      }, {
        id: 2,
        name: "园长助理"
      }, {
        id: 3,
        name: "教学负责人(教学园长、教学主任等)"
      }, {
        id: 4,
        name: "主配班老师"
      }, {
        id: 5,
        name: "其他"
      }],
      genderOptions: [{
        id: 0,
        name: '保密'
      }, {
        id: 1,
        name: "男"
      }, {
        id: 2,
        name: "女"
      }],
      roleOptions: [],
      // drawer Exp园所抽屉-数据
      drawerExp: false,
      drawerDataExp: {
        oId: "",
        uId: "",
        rId: "",
        username: "",
        mobile: "",
        email: "",
        gender: "",
        avatar: "",
        state: 1,
        province: '',
        city: '',
        district: '',
        expOrgName: '',
        expOrgAddress: '',
        expOrgType: '',
        expOrgNature: '',
        expOrgRegion: '',
        expOrgRemark: '',
        expOrgId: '',
        position: '',
        source: ''
      },
      typeOptions: [{
        id: 1,
        name: '幼儿园'
      }, {
        id: 2,
        name: '培训机构'
      }, {
        id: 3,
        name: '个人'
      }, {
        id: 4,
        name: '其他'
      }],
      natureOptions: [{
        id: 1,
        name: '民办'
      }, {
        id: 2,
        name: '公办'
      }, {
        id: 3,
        name: '企办'
      }, {
        id: 4,
        name: '其他'
      }],
      provinceList: [],
      cityList: [],
      districtList: [],
      // dialog弹框-删除弹框
      delData: {},
      delDialogVisible: false,
      // 删除弹框显示隐藏
      // 转换身份弹框
      changeDialog: false,
      changeDialogData: {
        id: '',
        oId: ''
      },
      changeToType: '',
      // 延长体验时间弹框
      expDialogVisible: false,
      expData: {},
      // 请求接口url
      delUrl: '',
      changeUrl: '',
      expDays: 7
    };
  },
  created: function created() {},
  mounted: function mounted() {
    if (parseInt(this.$route.query.id) === parseInt(this.Ls.getToken("nof_orgId"))) {
      // 路径传过来的机构id和缓存中一致时候，oId两者都行
      this.oId = this.$route.query.id || this.Ls.getToken("nof_orgId");
      this.interior = this.$route.query.interior;
      this.oName = this.$route.query.name || this.infoOrg.name;
    } else {
      // id不一致的时候，以路径值为准
      if (this.$route.query.id) {
        this.oId = this.$route.query.id;
        this.interior = this.$route.query.interior;
        this.oName = this.$route.query.name;
      } else {
        this.oId = this.Ls.getToken("nof_orgId");
        this.oName = this.infoOrg.name;
        this.infoOrg = this.infoOrg.infoOrg;
      }
    }
    this.initOrg();
    //获取角色列表
    this.initRole();
    //获取数据
    this.initData();
  },
  methods: {
    // ==================== 获取省市区相关函数 ====================
    getAllProvince: function getAllProvince() {
      var _this2 = this;
      this.service.axiosUtils({
        requestName: 'getProvince',
        data: {},
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this2.provinceList = res.data;
          } else {
            _this2.$toast.error(res.message);
          }
        }
      });
    },
    getCityFun: function getCityFun(val) {
      var _this3 = this;
      this.drawerDataExp.city = '';
      this.drawerDataExp.district = '';
      this.drawerDataExp.expOrgRegion = '';
      this.service.axiosUtils({
        requestName: 'getCity',
        data: {
          provinceId: val
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this3.cityList = res.data;
          } else {}
        }
      });
    },
    getDistrictFun: function getDistrictFun(id) {
      var _this4 = this;
      this.drawerDataExp.district = '';
      this.drawerDataExp.expOrgRegion = '';
      this.service.axiosUtils({
        requestName: 'getDistrict',
        data: {
          cityId: id
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this4.districtList = res.data;
          } else {}
        }
      });
    },
    setRegion: function setRegion(val) {
      this.drawerDataExp.expOrgRegion = val;
    },
    // ==================== 初始化 ====================
    // 初始化所选机构是否体验园
    initOrg: function initOrg() {
      // 判断当前机构 是否为体验园。nature === 5体验园，非5非体验园
      if (parseInt(this.infoOrg.nature) === 5 || parseInt(this.$route.query.nature) === 5) {
        this.isExpOrg = true;
        //获取省份
        this.getAllProvince();
        this.delUrl = 'deleteExpMember';
        this.changeUrl = 'changeExpMemberState';
      } else {
        this.isExpOrg = false;
        this.delUrl = 'deleteMember';
        this.changeUrl = 'changeMemberState';
      }
    },
    // 初始化角色数据getRole
    initRole: function initRole() {
      var _this5 = this;
      this.service.axiosUtils({
        requestName: "getRoleAll",
        data: {
          type: 1 //1是前台，2是后台
        },

        sucFn: function sucFn(res) {
          if (res.status === 1) {
            var tmpArray = [];
            if (_this5.isExpOrg) {
              //体验园-角色只有体验用户
              for (var i = 0; i < res.data.length; i++) {
                if (res.data[i].id === 10 || res.data[i].id === 8) {
                  // if(res.data[i].id === 10){
                  tmpArray.push(res.data[i]);
                }
              }
            } else {
              //非体验园-角色没有体验用户
              for (var _i = 0; _i < res.data.length; _i++) {
                if (res.data[_i].id !== 10) {
                  tmpArray.push(res.data[_i]);
                }
              }
            }
            _this5.roleOptions = tmpArray;
            _this5.filter[0].list = tmpArray;
          } else {
            _this5.$toast.error(res.message);
          }
        }
      });
    },
    // 初始化数据
    initData: function initData() {
      var _this6 = this;
      this.postListData.pageSize = this.pageSize;
      this.postListData.pageIndex = this.currentPage;
      this.postListData.oId = this.oId;
      var url;
      if (this.isExpOrg) {
        // 体验园所
        this.postListData.isExp = 1;
        url = 'getExpMemberList';
      } else {
        // 非体验园
        this.postListData.isExp = 0;
        url = 'getMemberList';
      }
      this.service.axiosUtils({
        requestName: url,
        data: this.postListData,
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this6.tableData = res.data.data;
            _this6.total = res.data.total;
            _this6.tableData.map(function (item) {
              item.mobileHide = _this6.$common.mGeTel(item.user.mobile);
              if (item.user.position) {
                // 曾添加过职位，文字/id
                item.user.position = parseInt(item.user.position);
              }
              item.positionName = _this6.$common.getName(item.user.position, _this6.positionOptions);
              if (!item.positionName) {
                item.positionName = '';
              }
              if (_this6.isExpOrg) {
                // 体验园数据处理
                if (item.exp) {
                  item.typeName = _this6.$common.getName(item.exp.expOrgType, _this6.typeOptions);
                  if (!item.typeName) {
                    item.typeName = '';
                  }
                  item.natureName = _this6.$common.getName(item.exp.expOrgNature, _this6.natureOptions);
                  if (!item.natureName) {
                    item.natureName = '';
                  }
                  if (item.exp.region) {
                    if (item.exp.region['parents']['parents'].name && item.exp.region['parents'].name && item.exp.region['parents'].name) {
                      item.regionName = item.exp.region['parents']['parents'].name + ' ' + item.exp.region['parents'].name + ' ' + item.exp.region.name;
                    } else {
                      item.regionName = '当前数据可能不完整';
                    }
                  } else {
                    item.regionName = '';
                  }
                } else {
                  item.typeName = '';
                  item.natureName = '';
                  item.regionName = '';
                }
              }
            });
          } else {
            _this6.$toast.error(res.message);
          }
        }
      });
    },
    // page-页码修改pagesize
    handleSizeChange: function handleSizeChange(page) {
      this.pageSize = page;
      this.initData();
    },
    // page-页码修改当前页码
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.initData();
    },
    // ==================== 筛选按钮函数 ====================
    search: function search(val) {
      var that = this;
      if (this.$common.checkSearchOrAllFilter(val)) {
        var filterData = val;
        this.postListData.rId = filterData[0].value;
        this.postListData.state = filterData[1].value;
        this.postListData.mobile = filterData[2].value;
      } else {
        this.postListData.rId = '';
        this.postListData.state = '';
        this.postListData.mobile = '';
      }
      that.initData();
    },
    reload: function reload() {
      for (var i = 0; i < this.filter.length; i++) {
        this.filter[i].value = '';
      }
      this.postListData.rId = '';
      this.postListData.state = '';
      this.postListData.mobile = '';
      this.currentPage = 1;
      this.initData();
    },
    // ==================== 添加函数 ====================
    addFun: function addFun() {
      this.drawerState = "add";
      if (this.isExpOrg) {
        this.drawerExp = true;
      } else {
        this.drawer = true;
      }
    },
    // ==================== table ====================
    // table-修改正常/禁用状态函数
    editState: function editState(row) {
      var _this7 = this;
      var rowState;
      if (row.state === 1) {
        rowState = -1;
      } else {
        rowState = 1;
      }
      this.service.axiosUtils({
        requestName: this.changeUrl,
        data: {
          id: row.id,
          state: rowState
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this7.$toast.success(res.message);
            if (row.state === 1) row.state = -1;else row.state = 1;
          } else {
            _this7.$toast.error(res.message);
          }
        }
      });
    },
    resetDrawer: function resetDrawer() {
      if (this.isExpOrg) {
        this.drawerDataExp = {
          oId: "",
          uId: "",
          rId: "",
          username: "",
          mobile: "",
          email: "",
          gender: "",
          avatar: "",
          state: 1,
          province: '',
          city: '',
          district: '',
          expOrgName: '',
          expOrgAddress: '',
          expOrgType: '',
          expOrgNature: '',
          expOrgRegion: '',
          expOrgRemark: '',
          expOrgId: '',
          position: '',
          source: ''
        };
      } else {
        this.drawerData = {
          oId: "",
          uId: "",
          rId: "",
          username: "",
          mobile: "",
          email: "",
          gender: "",
          avatar: "",
          state: 1,
          position: ''
        };
      }
    },
    // table-中操作函数
    detail: function detail(type, val) {
      var that = this;
      this.resetDrawer();
      if (type === "edit") {
        //编辑
        this.drawerState = "edit";
        this.cityList = [];
        this.districtList = [];
        if (this.isExpOrg) {
          this.drawerExp = true;
          setTimeout(function () {
            that.drawerDataExp.id = val.id;
            that.drawerDataExp.oId = val.orgId;
            that.drawerDataExp.uId = val.userId;
            that.drawerDataExp.rId = val.roleId;
            that.drawerDataExp.username = val.user.username;
            that.drawerDataExp.position = val.user.position;
            that.drawerDataExp.mobile = val.user.mobile;
            that.drawerDataExp.email = val.user.email;
            that.drawerDataExp.gender = val.user.gender;
            that.drawerDataExp.avatar = val.user.avatar;
            that.drawerDataExp.state = val.state;
            if (val.exp) {
              that.drawerDataExp.expOrgName = val.exp.expOrgName;
              that.drawerDataExp.expOrgAddress = val.exp.expOrgAddress;
              that.drawerDataExp.expOrgRemark = val.exp.expOrgRemark;
              that.drawerDataExp.expOrgId = val.exp.expOrgId;
              that.drawerDataExp.expOrgType = val.exp.expOrgType;
              that.drawerDataExp.expOrgNature = val.exp.expOrgNature;
              that.drawerDataExp.source = val.exp.source;
              that.drawerDataExp.expOrgType = that.$common.getName(that.drawerDataExp.expOrgType, that.typeOptions);
              if (!that.drawerDataExp.expOrgType) {
                that.drawerDataExp.expOrgType = '';
              }
              that.drawerDataExp.expOrgNature = that.$common.getName(that.drawerDataExp.expOrgNature, that.natureOptions);
              if (!that.drawerDataExp.expOrgNature) {
                that.drawerDataExp.expOrgNature = '';
              }
              if (val.exp.region && val.exp.region['parents'] && val.exp.region['parents']['parents']) {
                that.getCityFun(val.exp.region['parents']['parents'].id);
                that.getDistrictFun(val.exp.region['parents'].id);
                that.drawerDataExp.expOrgRegion = val.exp.region.id;
                that.drawerDataExp.province = val.exp.region['parents']['parents'].id;
                that.drawerDataExp.city = val.exp.region['parents'].id;
                that.drawerDataExp.district = val.exp.region.id;
              } else {
                that.drawerDataExp.expOrgRegion = '';
                that.drawerDataExp.province = '';
                that.drawerDataExp.city = '';
                that.drawerDataExp.district = '';
              }
            }
          }, 300);
        } else {
          this.drawer = true;
          setTimeout(function () {
            that.drawerData = {
              oId: val.orgId,
              uId: val.userId,
              rId: val.roleId,
              username: val.user.username,
              position: val.user.position,
              mobile: val.user.mobile,
              email: val.user.email,
              gender: val.user.gender,
              avatar: val.user.avatar,
              state: val.state,
              id: val.id
            };
          }, 300);
        }
      } else if (type === "del") {
        // 删除
        this.delDialogVisible = true;
        this.delData = {
          id: val.id,
          oId: val.orgId,
          rId: val.roleId,
          uId: val.userId,
          expOrgId: val.exp && val.exp.expOrgId || 0,
          nature: this.$route.query.nature
        };
      } else if (type === 'change') {
        // 转为教师
        this.changeDialogData = {
          id: val.id,
          oId: val.orgId
        };
        this.changeDialog = true;
        if (val.roleId === 8) {
          this.changeToType = 9;
        } else {
          this.changeToType = 8;
        }
      } else if (type === 'exp') {
        // 体验园延长体验时间
        this.expDialogVisible = true;
        this.expData = {
          id: val.id,
          oId: val.orgId,
          uId: val.user.id,
          expDate: val.expDate
        };
      }
    },
    getUserByMobile: function getUserByMobile() {
      var _this8 = this;
      var mobileData;
      if (this.isExpOrg) {
        mobileData = this.drawerDataExp.mobile;
      } else {
        mobileData = this.drawerData.mobile;
      }
      this.service.axiosUtils({
        requestName: "getUserByMobile",
        data: {
          mobile: mobileData
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            var dataUser = res.data;
            if (dataUser.position) {
              dataUser.position = parseInt(dataUser.position);
              // 曾添加过职位，文字/id
              // if(dataUser.position !== ("1" || "2" || "3" || "4" || "5")){
              //   dataUser.position = 5
              // }else{
              //   dataUser.position = parseInt(dataUser.position)
              // }
            }

            if (_this8.isExpOrg) {
              //体验园
              _this8.drawerDataExp.username = dataUser.username;
              _this8.drawerDataExp.avatar = dataUser.avatar;
              _this8.drawerDataExp.gender = dataUser.gender;
              _this8.drawerDataExp.email = dataUser.email;
              _this8.drawerDataExp.position = dataUser.position;
            } else {
              //非体验园
              _this8.drawerData.username = dataUser.username;
              _this8.drawerData.avatar = dataUser.avatar;
              _this8.drawerData.gender = dataUser.gender;
              _this8.drawerData.email = dataUser.email;
              _this8.drawerData.position = dataUser.position;
            }
          } else {
            _this8.$toast.error(res.message);
          }
        }
      });
    },
    // 编辑/添加drawer抽屉-取消函数
    cancelClick: function cancelClick() {
      this.resetDrawer();
      this.cityList = [];
      this.districtList = [];
      this.$nextTick(function () {
        this.$refs["form"].resetFields();
      });
      if (this.isExpOrg) {
        this.drawerExp = false;
      } else {
        this.drawer = false;
      }
    },
    // 编辑/添加drawer抽屉-确定函数
    editAddFun: function editAddFun() {
      var _this9 = this;
      var that = this;
      var url, postData;
      if (this.isExpOrg) {
        // 体验园所
        that.drawerDataExp.isExp = 1;
        that.drawerDataExp.oId = that.oId;
        if (that.drawerState === "edit") {
          //修改
          url = "editExpMember";
        } else {
          //添加
          url = "addExpMember";
        }
        postData = that.drawerDataExp;
      } else {
        that.drawerData.isExp = 0;
        that.drawerData.oId = that.oId;
        // （20240105，添加/修改人员都需要传是否为内部机构字段）
        that.drawerData.interior = that.interior;
        if (that.drawerState === "edit") {
          //修改
          url = "editMember";
        } else {
          //添加
          url = "addMember";
        }
        postData = that.drawerData;
      }
      that.service.axiosUtils({
        requestName: url,
        data: postData,
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            that.$toast.success(res.message);
            that.resetDrawer();
            if (_this9.isExpOrg) {
              that.drawerExp = false;
            } else {
              that.drawer = false;
            }
            if (that.drawerState === "add") {
              that.currentPage = 1;
            }
            that.$nextTick(function () {
              that.$refs["form"].resetFields();
            });
            that.initData();
          } else {
            that.$toast.error(res.message);
          }
        }
      });
    },
    confirmClick: function confirmClick(formName) {
      var _this10 = this;
      var that = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (that.drawerData.email) {
            if (!/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(_this10.drawerData.email)) {
              _this10.$toast.error("请检查邮箱是否符合要求");
              return;
            }
          }
          var _this = _this10;
          if (_this10.file) {
            uploadFile({
              folderName: "logo",
              complete: function complete(res, url) {
                if (_this.isExpOrg) {
                  _this.drawerDataExp.avatar = url;
                } else {
                  _this.drawerData.avatar = url;
                }
                _this.editAddFun();
              }
            }, _this10.file);
          } else {
            _this.editAddFun();
          }
        }
      });
    },
    // 删除dialog弹框-删除弹框-确定函数
    confirmFun: function confirmFun() {
      var _this11 = this;
      //删除当前的成员
      this.service.axiosUtils({
        requestName: this.delUrl,
        data: this.delData,
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this11.$toast.success(res.message);
            _this11.delData = {};
            _this11.delDialogVisible = false;
            _this11.initData();
          } else {
            _this11.$toast.error(res.message);
          }
        }
      });
    },
    // 身份转化dialog弹框-非体验园中身份转化-确定函数
    changeConfirmFun: function changeConfirmFun() {
      var _this12 = this;
      var that = this;
      that.service.axiosUtils({
        requestName: 'convertOrgUser',
        data: {
          id: this.changeDialogData.id,
          oId: this.changeDialogData.oId,
          rId: this.changeToType
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            that.$toast.success(res.message);
            _this12.changeDialog = false;
            that.initData();
          } else {
            that.$toast.error(res.message);
          }
        }
      });
    },
    // 延长用户授权时间dialog弹框-体验园延长用户授权时间-确定函数
    expConfirmFun: function expConfirmFun() {
      var that = this;
      that.expData.days = parseInt(that.expDays);
      that.service.axiosUtils({
        requestName: 'extendExpDate',
        data: that.expData,
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            that.$toast.success(res.message);
            that.expDialogVisible = false;
            that.initData();
          } else {
            that.$toast.error(res.message);
          }
        }
      });
    },
    // ====================== 头像 ======================
    //修改成员图标
    changeFile: function changeFile(file, fileList) {
      var _this = this;
      _this.file = file.raw;
      var reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = function (e) {
        if (_this.isExpOrg) {
          _this.drawerDataExp.avatar = this.result;
        } else {
          _this.drawerData.avatar = this.result;
        }
      };
    },
    //上传图片转base64
    handleAvatarSuccess: function handleAvatarSuccess(response, uploadFile) {
      _this13.imageUrl = URL.createObjectURL(uploadFile.raw);
    }
  }
};